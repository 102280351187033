import React, { useState, useEffect } from 'react';
import { createBooking, getAllAvailableSlots, getAllUsers, updateBooking } from '../api/parkingAPI';
import { toast } from 'react-toastify';
import Select from 'react-select';

function ManageBookings() {
    const [formData, setFormData] = useState({
        userId: '',
        slotId: '',
        vehicleType: '',
        startTime: '',
        endTime: '',
        paymentStatus: false,
    });
    const [bookingId, setBookingId] = useState('');
    const [message, setMessage] = useState('');
    const [users, setUsers] = useState([]);
    const [availableSlots, setAvailableSlots] = useState([]);

console.log(users, formData);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const usersResponse = await getAllUsers();
                const slotsResponse = await getAllAvailableSlots();
                setUsers(usersResponse.data);
                setAvailableSlots(slotsResponse.data);
            } catch (error) {
                console.error('Error fetching data:', error.response?.data?.error || error.message);
            }
        };
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({ ...formData, [name]: type === 'checkbox' ? checked : value });
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setFormData({ ...formData, [name]: selectedOption ? selectedOption.value : '' });
    };

    const handleCreate = async (e) => {
        e.preventDefault();
        try {
            const response = await createBooking(formData);
            toast.success(`Booking created: ${response.data._id}`);
            setMessage(`Booking created: ${response.data._id}`);
        } catch (error) {
            toast.error(`Error: ${error.response?.data?.error || 'Unknown error'}`);
            setMessage(`Error: ${error.response?.data?.error || 'Unknown error'}`);
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        try {
            const response = await updateBooking(bookingId, formData);
            toast.success(`Booking updated: ${response.data._id}`);
            setMessage(`Booking updated: ${response.data._id}`);
        } catch (error) {
            toast.error(`Error: ${error.response?.data?.error || 'Unknown error'}`);
            setMessage(`Error: ${error.response?.data?.error || 'Unknown error'}`);
        }
    };

    return (
        <div className="p-4 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">Manage Bookings</h2>
            <form onSubmit={bookingId ? handleUpdate : handleCreate} className="space-y-4">
                {/* User ID Select */}
                <div>
                    <Select
                        name="userId"
                        options={users.map(user => ({
                            value: user._id,
                            label: `${user.name} (${user.vehicleNumber})`
                        }))}
                        onChange={handleSelectChange}
                        placeholder="Select User"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        required
                    />
                </div>

                {/* Slot ID Select */}
                <div>
                    <Select
                        name="slotId"
                        options={availableSlots.map(slot => ({
                            value: slot.slotId,
                            label: `${slot.slotId} (${slot.location})`
                        }))}
                        onChange={handleSelectChange}
                        placeholder="Select Parking Slot"
                        className="react-select-container"
                        classNamePrefix="react-select"
                        required
                    />
                </div>

                {/* Vehicle Type Input */}
                <input
                    type="text"
                    name="vehicleType"
                    value={formData.vehicleType}
                    onChange={handleChange}
                    placeholder="Vehicle Type"
                    className="border p-2 w-full"
                    required
                />
                {/* Start Time Input */}
                <input
                    type="datetime-local"
                    name="startTime"
                    value={formData.startTime}
                    onChange={handleChange}
                    className="border p-2 w-full"
                    required
                />
                {/* End Time Input */}
                <input
                    type="datetime-local"
                    name="endTime"
                    value={formData.endTime}
                    onChange={handleChange}
                    className="border p-2 w-full"
                    required
                />
                {/* Payment Status Checkbox */}
                <label className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        name="paymentStatus"
                        checked={formData.paymentStatus}
                        onChange={handleChange}
                    />
                    <span>Payment Status</span>
                </label>

                {/* Booking ID Input (for update) */}
                <input
                    type="text"
                    placeholder="Booking ID (for update)"
                    value={bookingId}
                    onChange={(e) => setBookingId(e.target.value)}
                    className="border p-2 w-full"
                />

                {/* Submit Button */}
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                    {bookingId ? 'Update Booking' : 'Create Booking'}
                </button>
            </form>

            {/* Message Display */}
            {message && <p className="mt-4">{message}</p>}
        </div>
    );
}

export default ManageBookings;
