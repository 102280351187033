import React, { useState } from 'react';
import { searchBookingByVehicle } from '../api/parkingAPI';

function SearchBooking() {
    const [vehicleNumber, setVehicleNumber] = useState('');
    const [booking, setBooking] = useState(null);
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await searchBookingByVehicle(vehicleNumber);
            setBooking(response.data);
            setMessage('');
        } catch (error) {
            setMessage(`Error: ${error.response.data.error}`);
            setBooking(null);
        }
    };

    return (
        <div className="p-4 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">Search Booking by Vehicle Number</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    name="vehicleNumber"
                    value={vehicleNumber}
                    onChange={(e) => setVehicleNumber(e.target.value)}
                    placeholder="Vehicle Number"
                    className="border p-2 w-full"
                    required
                />
                <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                    Search
                </button>
            </form>
            {message && <p className="mt-4">{message}</p>}
            {booking && (
                <div className="mt-4 border p-4">
                    <h3 className="text-lg font-bold">Booking Details</h3>
                    <p>Slot ID: {booking.slotId}</p>
                    <p>Vehicle Type: {booking.vehicleType}</p>
                    <p>Start Time: {new Date(booking.startTime).toLocaleString()}</p>
                    <p>End Time: {new Date(booking.endTime).toLocaleString()}</p>
                    <p>Payment Status: {booking.paymentStatus ? 'Paid' : 'Unpaid'}</p>
                </div>
            )}
        </div>
    );
}

export default SearchBooking;
