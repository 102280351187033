import axios from 'axios';

const api = axios.create({
    // baseURL: 'http://localhost:4000/api',
    baseURL: 'https://transport-parking-system.onrender.com/api',
    headers: {
        'Content-Type': 'application/json'
    }
});

export const registerUser = (data) => api.post('/register', data);
export const addParkingSlot = (data) => api.post('/parking-slots', data);
export const deleteParkingSlot = (slotId) => api.delete(`/parking-slots/${slotId}`);
export const listParkingSlots = (vehicleType) => api.get(`/parking-slots`, { params: { vehicleType } });
export const setPricing = (data) => api.post('/pricing', data);
export const createBooking = (data) => api.post('/bookings', data);
export const searchBookingByVehicle = (vehicleNumber) => api.get(`/booking/searchByVehicle`, { params: { vehicleNumber } });
export const updateBooking = (bookingId, data) => api.put(`/bookings/${bookingId}`, data);
export const getAllAvailableSlots = () => api.get('/bookings/availableSlots');
export const getAllUsers = () => api.get('/booking/all');