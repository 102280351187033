import React, { useState } from 'react';
import { listParkingSlots } from '../api/parkingAPI';

function ParkingSlotList() {
    const [vehicleType, setVehicleType] = useState('');
    const [slots, setSlots] = useState([]);
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setVehicleType(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await listParkingSlots(vehicleType);
            setSlots(response.data);
        } catch (error) {
            setMessage(`Error: ${error.response.data.error}`);
        }
    };

    return (
        <div className="p-4 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">Parking Slots</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    name="vehicleType"
                    value={vehicleType}
                    onChange={handleChange}
                    placeholder="Enter vehicle type (car, motorcycle)"
                    className="border p-2 w-full"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                    Find Slots
                </button>
            </form>

            {message && <p className="mt-4">{message}</p>}

            <div className="mt-4">
                {slots.length > 0 ? (
                    <ul className="space-y-2">
                        {slots.map((slot) => (
                            <li key={slot.slotId} className="border p-2">
                                <strong>Slot ID:</strong> {slot.slotId} <br />
                                <strong>Location:</strong> {slot.location}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No slots available.</p>
                )}
            </div>
        </div>
    );
}

export default ParkingSlotList;
