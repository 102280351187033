import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import RegisterUser from './components/RegisterUser';
import AddParkingSlot from './components/AddParkingSlot';
import ManageBookings from './components/ManageBookings';
import ParkingSlotList from './components/ParkingSlotList';
import Pricing from './components/Pricing';
import SearchBooking from './components/SearchBooking';
import HomePage from './components/HomePage';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

function App() {
  return (
    <Router>
      <div className="min-h-screen bg-gray-100">
        <nav className="bg-gray-800 text-white p-4">
          <ul className="flex space-x-6">
            <li>
              <Link to="/" className="hover:text-yellow-400">
                Home
              </Link>
            </li>
            <li>
              <Link to="/register" className="hover:text-yellow-400">
                Register User
              </Link>
            </li>
            <li>
              <Link to="/add-slot" className="hover:text-yellow-400">
                Add Parking Slot
              </Link>
            </li>
            <li>
              <Link to="/bookings" className="hover:text-yellow-400">
                Manage Bookings
              </Link>
            </li>
            <li>
              <Link to="/slots" className="hover:text-yellow-400">
                Parking Slots
              </Link>
            </li>
            <li>
              <Link to="/pricing" className="hover:text-yellow-400">
                Pricing
              </Link>
            </li>
            <li>
              <Link to="/search-booking" className="hover:text-yellow-400">
                Search Booking
              </Link>
            </li>
          </ul>
        </nav>

        {/* Main Content */}
        <div className="p-6">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/register" element={<RegisterUser />} />
            <Route path="/add-slot" element={<AddParkingSlot />} />
            <Route path="/bookings" element={<ManageBookings />} />
            <Route path="/slots" element={<ParkingSlotList />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/search-booking" element={<SearchBooking />} />
          </Routes>
        </div>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
