import React, { useState } from 'react';
import { setPricing } from '../api/parkingAPI';
import { toast } from 'react-toastify';  

function Pricing() {
    const [formData, setFormData] = useState({
        vehicleType: '',
        dailyRate: '',
        weeklyRate: '',
        monthlyRate: '',
    });
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await setPricing(formData);
            toast.success(`Pricing set for: ${response.data.vehicleType}`);  // Success toast
            setMessage(`Pricing set for: ${response.data.vehicleType}`);
        } catch (error) {
            toast.error(`Error: ${error.response?.data?.error || 'Unknown error'}`);  // Error toast
            setMessage(`Error: ${error.response?.data?.error || 'Unknown error'}`);
        }
    };

    return (
        <div className="p-4 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">Set Pricing</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    name="vehicleType"
                    value={formData.vehicleType}
                    onChange={handleChange}
                    placeholder="Vehicle Type"
                    className="border p-2 w-full"
                    required
                />
                <input
                    type="number"
                    name="dailyRate"
                    value={formData.dailyRate}
                    onChange={handleChange}
                    placeholder="Daily Rate"
                    className="border p-2 w-full"
                    required
                />
                <input
                    type="number"
                    name="weeklyRate"
                    value={formData.weeklyRate}
                    onChange={handleChange}
                    placeholder="Weekly Rate"
                    className="border p-2 w-full"
                    required
                />
                <input
                    type="number"
                    name="monthlyRate"
                    value={formData.monthlyRate}
                    onChange={handleChange}
                    placeholder="Monthly Rate"
                    className="border p-2 w-full"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                    Set Pricing
                </button>
            </form>
            {message && <p className="mt-4">{message}</p>}
        </div>
    );
}

export default Pricing;
