// src/components/HomePage.js
import React from 'react';

function HomePage() {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
            <h1 className="text-5xl font-bold text-gray-800 mb-6">Welcome to the Parking System</h1>
            <p className="text-xl text-gray-600 mb-6">
                Manage your parking slots, bookings, pricing, and users all in one place.
            </p>
            <div className="flex flex-wrap justify-center space-x-6">
                <a
                    href="/register"
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
                >
                    Register User
                </a>
                <a
                    href="/add-slot"
                    className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
                >
                    Add Parking Slot
                </a>
                <a
                    href="/bookings"
                    className="bg-orange-500 text-white px-4 py-2 rounded-lg hover:bg-orange-600"
                >
                    Manage Bookings
                </a>
                <a
                    href="/pricing"
                    className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600"
                >
                    Pricing
                </a>
            </div>
        </div>
    );
}

export default HomePage;
