import React, { useState } from 'react';
import { addParkingSlot } from '../api/parkingAPI';
import { toast } from 'react-toastify';  // Import toast

function AddParkingSlot() {
    const [formData, setFormData] = useState({ slotId: '', vehicleType: '', location: '' });
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await addParkingSlot(formData);
            toast.success(`Parking slot added: ${response.data.slotId}`);  // Success toast
            setMessage(`Parking slot added: ${response.data.slotId}`);
        } catch (error) {
            toast.error(`Error: ${error.response?.data?.error || 'Unknown error'}`);  // Error toast
            setMessage(`Error: ${error.response?.data?.error || 'Unknown error'}`);
        }
    };

    return (
        <div className="p-4 max-w-md mx-auto">
            <h2 className="text-xl font-bold mb-4">Add Parking Slot</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <input
                    type="text"
                    name="slotId"
                    value={formData.slotId}
                    onChange={handleChange}
                    placeholder="Slot ID"
                    className="border p-2 w-full"
                    required
                />
                <input
                    type="text"
                    name="vehicleType"
                    value={formData.vehicleType}
                    onChange={handleChange}
                    placeholder="Vehicle Type"
                    className="border p-2 w-full"
                    required
                />
                <input
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    placeholder="Location"
                    className="border p-2 w-full"
                    required
                />
                <button type="submit" className="bg-green-500 text-white px-4 py-2 rounded">
                    Add Slot
                </button>
            </form>
            {message && <p className="mt-4">{message}</p>}
        </div>
    );
}

export default AddParkingSlot;
